// FONT IMPORT
@font-face {
  font-family: 'Instrument Sans';
	font-style: normal;
  font-weight: 300;
  src: url('../fonts/InstrumentSans-Regular.ttf');
}

@font-face {
  font-family: 'Instrument Sans';
	font-style: italic;
  font-weight: 300;
  src: url('../fonts/InstrumentSans-Italic.ttf');
}

@font-face {
  font-family: 'Instrument Sans';
	font-style: normal;
  font-weight: 400;
  src: url('../fonts/InstrumentSans-Medium.ttf');
}

@font-face {
  font-family: 'Instrument Sans';
	font-style: italic;
  font-weight: 400;
  src: url('../fonts/InstrumentSans-MediumItalic.ttf');
}

@font-face {
  font-family: 'Instrument Serif';
	font-style: normal;
  font-weight: 300;
  src: url('../fonts/InstrumentSerif-Regular.ttf');
}

@font-face {
  font-family: 'Instrument Serif';
	font-style: italic;
  font-weight: 300;
  src: url('../fonts/InstrumentSerif-Italic.ttf');
}


// SASS VARIABLES
$color-neutral-black: #000000;
$color-neutral-000: #242424;
$color-neutral-100: #565656;
$color-neutral-200: #888888;
$color-neutral-300: #BABABA;
$color-neutral-400: #ECECEC;
$color-neutral-500: #FAFAFA;
$color-neutral-white: #FFFFFF;

$color-brand-100: #2A7882;
$color-brand-200: #26B78E;

$color-box-200: #984BFC;

$font-title: 'Instrument Serif', Georgia, Times, 'Times New Roman', serif;
$font-text: 'Instrument Sans', Arial, 'Helvetica Neue', Helvetica, sans-serif;


// MIXINS
@mixin headingH3 {
  font-family: $font-title;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 1.2;
  color: $color-neutral-black;
  margin: 0 0 8px 0;

  @media screen and (max-width: 768px){
    font-size: 24px;
  }
}

@mixin hiperlink {
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  color: $color-brand-200;
  display: inline;
  width: fit-content;
  transition: all 0.2s ease-in-out;
  margin-top: 8px;
  // cursor: url(../images/cursor.svg) 0 0, auto;

  &:hover {
    color: $color-neutral-000;
  }
}


// ANIMATIONS
@keyframes revealOnScroll {
	from {
		opacity: 0;
    filter: grayscale(1);
    transform: translateY(48px)
	}
	to {
		opacity: 1;
    filter: grayscale(0);
    transform: translateY(0)
	}
}


// HOVER EFFECT
.hoverEffect {
  border: 1px solid $color-box-200;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;

  &__corner {
    display: block;
    position: absolute;
    background-color: white;
    border: 1px solid $color-box-200;
    box-sizing: border-box;
    width: 8px;
    height: 8px;

    &--tl {
      top: -5px;
      left: -5px;
    }

    &--tr {
      top: -5px;
      right: -5px;
    }

    &--br {
      bottom: -5px;
      right: -5px;
    }

    &--bl {
      bottom: -5px;
      left: -5px;
    }
  }

  &__label {
    color: white;
    background-color: $color-box-200;
    font-size: 12px;
    font-weight: 400;
    line-height: 2.0;
    padding: 0 8px;
    border-radius: 4px;
    display: inline-block;
    position: absolute;
    left: 50%;
    bottom: -28px;
    transform: translateX(-50%);
  }
}


// FLOATING CTA
.floatingCTA {
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $color-neutral-000;
  padding: 12px 20px;
  border-radius: 40px;
  display: none;

  &__link {
    color: $color-neutral-500;
    font-weight: 400;
    text-decoration: none;
  }

  @media screen and (max-width: 768px){
    display: block;
  }
}


// GENERAL
html,
body {
  padding: 0;
  margin: 0;
  font-family: $font-text;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
  background-color: $color-neutral-500;
  color: $color-neutral-200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: antialiased;
  // cursor: url(../images/cursor.svg) 0 0, auto;

  @media screen and (max-width: 768px){
    font-size: 16px;
  }  
}

p {
  padding: 0;
  margin: 0;
}

.wrapper {
  margin: 0 80px;
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  max-width: 1600px;

  @media screen and (max-width: 1024px){
    margin: 0 40px;
  }

  @media screen and (max-width: 768px){
    margin: 0 16px;
  }
}

.sidebar {
  height: calc(100vh - 160px);
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  flex: 1;

  @media screen and (max-width: 768px){
    display: none;
  }

  &__logo {
    width: fit-content;
  }
}

.content {
  flex: 3;
}

article {
  height: auto;
  min-height: calc(100vh - 240px);
  padding: 80px 0 160px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px){
    padding: 64px 0;
  }
}

section {
}

.articleHeading {
  margin-bottom: 72px;

  @media screen and (max-width: 768px){
    margin-bottom: 36px;
  }

  &__title {
    font-family: $font-title;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 1.2;
    color: $color-neutral-black;
    margin: 0;

    @media screen and (max-width: 768px){
      font-size: 32px;
    }
  }
  
  &__subtitle {
    font-family: $font-title;
    font-style: italic;
    font-weight: 300;
    font-size: 40px;
    line-height: 1.2;
    color: $color-neutral-200;
    margin: 0;

    @media screen and (max-width: 768px){
      font-size: 32px;
    }
  }
}

.articleLogo {
  margin-bottom: 40px;
  width: fit-content;
  display: none;

  &--visible {
    display: block;
  }

  @media screen and (max-width: 768px){
    display: block;
  }
}


// MENU
.mainMenu {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
  }

  &__link,
  &__link--details {
    @include hiperlink();
    line-height: 2;
    color: $color-neutral-200;
    position: relative;

    &:before {
      content: '';
      width: 0;
      height: 2px;
      position: absolute;
      left: 0;
      top: 9px;
      display: block;
      background-color: $color-neutral-black;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      color: $color-neutral-000;
    }

    &--active {
      padding-left: 24px;
      color: $color-neutral-black;

      &:before {
        width: 16px;
      }
    }
  }
}


// INTRO
#intro {
  padding: 80px 0;
  min-height: calc(100vh - 160px);

  .introHeading {
    &__title {
      font-family: $font-title;
      font-style: normal;
      font-weight: 300;
      font-size: 56px;
      line-height: 1.25;
      color: $color-neutral-000;
      margin: 0;

      @media screen and (max-width: 768px){
        font-size: 40px;
      }
    }

    &__subtitle {
      font-family: $font-title;
      font-style: italic;
      font-weight: 300;
      font-size: 48px;
      line-height: 1.25;
      color: $color-neutral-200;
      margin: 0;

      a {
        @include hiperlink();
        font-style: italic;
      }

      @media screen and (max-width: 768px){
        font-size: 36px;
      }
    }
  }
}


// ABOUT
#about {
  padding: 80px 0;
  min-height: calc(100vh - 160px);

  .aboutBlurb {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 16px;

    view-timeline: --revealing-aboutBlurb block;
    animation: ease-in-out revealOnScroll both;
    animation-timeline: --revealing-aboutBlurb;
    animation-range: entry 0% contain 20%;

    @media screen and (max-width: 768px){
      font-size: 18px;
    }

    strong {
      font-weight: 400;
    }
  }
}


// FEATURED WORK
#featured {
  .featuredItem {
    margin-bottom: 120px;

    view-timeline: --revealing-featuredItem block;
    animation: ease-in-out revealOnScroll both;
    animation-timeline: --revealing-featuredItem;
    animation-range: entry 0% contain 20%;

    @media screen and (max-width: 768px){
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      @include headingH3();
    }

    &__blurb {
      a {
        @include hiperlink();
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      column-gap: 24px;
      row-gap: 16px;
      margin-top: 24px;

      @media screen and (max-width: 768px){
        grid-template-columns: repeat(2, 1fr);
        column-gap: 16px;
        row-gap: 16px;
      }

      @media screen and (max-width: 480px){
        grid-template-columns: auto;
      }
    }

    &__image {
      width: 100%;
      background-color: $color-neutral-400;
      border-radius: 8px;
      border: 1px solid rgba($color-neutral-300, 0.1);

      view-timeline: --revealing-featuredItemImage block;
      animation: ease-in-out revealOnScroll both;
      animation-timeline: --revealing-featuredItemImage;
      animation-range: entry 0% contain 20%;

      &:nth-child(4){
        display: none;

        @media screen and (max-width: 768px){
          display: block;
        }
    
        @media screen and (max-width: 480px){
          display: none;
        }
      }

      @for $i from 1 through 3 {
        &:nth-child(3n+#{$i}){
          animation-range: entry $i * 2 * 10% contain $i * 2 * 10%;
        }
      }
    }
  }
}


// WORK HISTORY
#work {
  view-timeline: --enter-work block;

  .workItem {
    margin-bottom: 80px;
    display: flex;
    flex-direction: row;
    column-gap: 24px;

    view-timeline: --revealing-workItem block;
    animation: ease-in-out revealOnScroll both;
    animation-timeline: --revealing-workItem;
    animation-range: entry 0% contain 20%;

    @media screen and (max-width: 768px){
      flex-direction: column;
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__date {
      flex: 1;
    }

    &__details {
      display: flex;
      flex-direction: column;
      flex: 5;
    }

    &__position {
      color: $color-neutral-black;
    }

    &__company {
      font-size: 14px;
      margin-bottom: 8px;
    }

    &__webpage {
      display: none;
    }

    &__blurb {
      @media screen and (max-width: 768px){
        display: none;
      }
    }
  }
}


// ARTICLES
#articles {
  .articlesItem {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    margin-bottom: 80px;
    position: relative;

    view-timeline: --revealing-articlesItem block;
    animation: ease-in-out revealOnScroll both;
    animation-timeline: --revealing-articlesItem;
    animation-range: entry 0% contain 20%;

    @media screen and (max-width: 768px){
      flex-direction: column;
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__thumbnail {
      flex: 1;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 4px;

        @media screen and (max-width: 768px){
          max-width: 160px;
          margin-bottom: 8px;
        }
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      flex: 5;
    }

    &__title {
      @include headingH3();
    }

    &__link {
      @include hiperlink();
    }

    &__blurb {
    }

    &__date {
      font-size: 14px;
      padding: 0;
      margin: 0;
      margin-bottom: 8px;
      display: none;
    }
  }
}


// PLAYGROUND
#playground {
  .playgroundItem {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    margin-bottom: 80px;
    position: relative;

    view-timeline: --revealing-playgroundItem block;
    animation: ease-in-out revealOnScroll both;
    animation-timeline: --revealing-playgroundItem;
    animation-range: entry 0% contain 20%;

    @media screen and (max-width: 768px){
      flex-direction: column;
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__thumbnail {
      flex: 1;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 4px;

        @media screen and (max-width: 768px){
          max-width: 160px;
          margin-bottom: 8px;
        }
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      flex: 5;
      z-index: 1;
    }

    &__title {
      @include headingH3();
    }

    &__link {
      @include hiperlink();
    }

    &__blurb {
    }
  }
}


// CONTACT
#contact {
  @media screen and (max-width: 768px){
    padding-bottom: 120px;
  }

  .contactItem {
    display: flex;
    flex-direction: column;
    column-gap: 24px;
    margin-bottom: 80px;
    position: relative;

    @media screen and (max-width: 768px){
      margin-bottom: 40px;
    }

    view-timeline: --revealing-contactItem block;
    animation: ease-in-out revealOnScroll both;
    animation-timeline: --revealing-contactItem;
    animation-range: entry 0% contain 20%;

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      @include headingH3();
    }

    &__blurb {
      a {
        @include hiperlink();
      }
    }
  }

  .networksList {
    list-style: none;
    padding: 0;
    display: flex;
    column-gap: 40px;

    @media screen and (max-width: 768px){
      flex-direction: column;
      row-gap: 24px;
    }

    &__item {
      --color: #{$color-neutral-100};

      &:hover {
        --color: #{$color-brand-200};
      }
    }

    &__link {
      text-decoration: none;
      font-weight: 400;
      display: flex;
      column-gap: 8px;
      line-height: 1;
      align-items: center;
      color: var(--color);
      transition: all 0.2s ease-in-out;
      // cursor: url(../images/cursor.svg) 0 0, auto;
    }

    &__icon {
      width: 16px;
      height: 16px;
      fill: var(--color);
      transition: all 0.2s ease-in-out;
    }
  }
}


// PASSWORD FORM
#login {
  padding: 80px 0;
  min-height: calc(100vh - 160px);
}

#passwordForm {
  &__password {
    font-family: $font-text;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    line-height: 1.6;
    color: $color-neutral-000;
    background-color: $color-neutral-500;
    padding: 0 20px;
    border-radius: 8px;
    border: 2px solid $color-neutral-000;
    box-sizing: border-box;
    height: 48px;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 480px){
      width: 100%;
    }

    &:focus,
    &:focus-within,
    &:focus-visible {
      outline: 1px auto $color-brand-200;
    }
  }

  &__button {
    font-family: $font-text;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    line-height: 1.6;
    color: $color-neutral-500;
    background-color: $color-neutral-000;
    padding: 0 20px;
    border-radius: 8px;
    border: none;
    height: 48px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 480px){
      margin-top: 8px;
      width: 100%;
    }

    &:hover {
      background-color: $color-brand-200;
    }
  }

  &__result {
    font-family: $font-text;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    line-height: 1.6;
    color: $color-neutral-100;
    background-color: $color-neutral-400;
    padding: 0 20px;
    border-radius: 8px;
    border: none;
    height: 48px;
    margin-top: 24px;
    width: auto;
    display: none;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: 480px){
      width: auto;
    }
  }
}

.login {
  &__blurb {
    font-size: 18px;
    line-height: 1.6;
    margin-top: 24px;

    @media screen and (max-width: 768px){
      font-size: 16px;
    }

    a {
      @include hiperlink();
    }
  }
}


// PROJECT DETAILS
#details {
  .detailsItem {
    margin-bottom: 40px;

    // view-timeline: --revealing-detailsItem block;
    // animation: ease-in-out revealOnScroll both;
    // animation-timeline: --revealing-detailsItem;
    // animation-range: entry 0% contain 20%;

    @media screen and (max-width: 768px){
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      @include headingH3();
    }

    &__blurb {
      a {
        @include hiperlink();
      }
    }

    &__image {
      width: 100%;
      background-color: $color-neutral-400;
      border-radius: 8px;
      border: 1px solid rgba($color-neutral-300, 0.1);
      margin-bottom: 16px;

      // view-timeline: --revealing-detailsItemImage block;
      // animation: ease-in-out revealOnScroll both;
      // animation-timeline: --revealing-detailsItemImage;
      // animation-range: entry 0% contain 5%;
    }
  }
}
